/* Custom Scrollbar Styles */
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: rgba(203, 213, 225, 0.4) transparent;
  transition: scrollbar-color 0.3s ease;
}

/* For Webkit browsers (Chrome, Safari, etc) */
.custom-scrollbar::-webkit-scrollbar {
  width: 4px; /* Extra thin scrollbar */
  height: 4px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(203, 213, 225, 0.4); /* Light gray with transparency */
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

/* Show slightly darker scrollbar on hover */
.custom-scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: rgba(148, 163, 184, 0.6); /* Darker gray with transparency */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(148, 163, 184, 0.6); /* Even darker on thumb hover */
}

/* Hide scrollbar when not scrolling (works in Firefox) */
.custom-scrollbar {
  scrollbar-color: transparent transparent;
}

.custom-scrollbar:hover {
  scrollbar-color: rgba(148, 163, 184, 0.6) transparent;
}

/* For Firefox */
@supports (scrollbar-color: auto) {
  .custom-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: rgba(203, 213, 225, 0.4) transparent;
  }
  
  .custom-scrollbar:hover {
    scrollbar-color: rgba(148, 163, 184, 0.6) transparent;
  }
}

/* Hide scrollbar when not hovering */
@media (min-width: 768px) {
  .custom-scrollbar:not(:hover)::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  
  .custom-scrollbar:not(:hover) {
    scrollbar-color: transparent transparent;
  }
}
