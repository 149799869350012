.table td,
.table th {
  border: 1px solid #e2e8f0;
}

.p-dialog .p-dialog-content {
  border: 1px solid #e2e8f0;
}

.lk-button.lk-chat-toggle {
	display: none !important;
  }