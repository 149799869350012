/* Base Typography - Add these at the top */
html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  padding: 0;
  min-width: 320px;
  overflow-x: hidden;
}

/* Normalize box sizing */
*, *::before, *::after {
  box-sizing: border-box;
}

/* Typography normalization */
h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: 1.2;
}

p {
  margin: 0;
  padding: 0;
}

/* Viewport handling */
.max-w-7xl {
  max-width: min(100vw - 2rem, 80rem);
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

/* Base Typography */
.breadcrumb {
  @apply flex items-center text-sm text-gray-500 mb-6;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.breadcrumb-item {
  @apply hover:text-blue-600;
}

.breadcrumb-separator {
  @apply mx-2;
}

.breadcrumb-active {
  @apply text-gray-900 font-medium;
}

/* Page Headers */
.page-header {
  @apply mb-8;
  max-width: 100%;
}

.page-title {
  @apply text-xl font-medium text-gray-900 mb-2;
  word-wrap: break-word;
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.page-subtitle {
  @apply text-sm text-gray-600;
  max-width: 100%;
}

/* Grid Headers */
.grid-header {
  @apply text-sm font-semibold text-gray-700;
}

/* Card Headers */
.card-header {
  @apply text-lg font-medium text-gray-900;
}

/* Section Headers */
.section-header {
  @apply text-lg font-medium text-gray-900 mb-4;
}

/* Add responsive container class */
.responsive-container {
  width: 100%;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 640px) {
  .responsive-container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .responsive-container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .responsive-container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .responsive-container {
    max-width: 1280px;
  }
}

/* Fix for Edge line breaks */
.word-break-normal {
  word-break: normal;
  overflow-wrap: break-word;
}

/* Cross-browser flexbox fixes */
.flex-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* Fix for Edge content width issues */
.content-wrapper {
  width: 100%;
  min-width: 0; /* Prevents content from overflowing flex containers in Edge */
  max-width: 100%;
}
